import React from 'react'
import './App.scss'

import {
  Dashboard,
  DataPage,
  Device,
  Home,
  Landing,
  Login,
  Notifications,
  Security,
  Settings
} from './pages'
import { Menu } from './components'

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className=''>
        <div className=''>
          <Routes>
            <Route path={'/'} element={<Landing />} />
            <Route path={'/app/dashboard'} element={<Dashboard />} />
            <Route path={'/app/login'} element={<Login />} />
            <Route path={'/app/security'} element={<Security />} />
            <Route path={'/app/device'} element={<Device />} />
            <Route path={'/app/notifications'} element={<Notifications />} />
            <Route path={'/app/settings'} element={<Settings />} />
            <Route path={'/admin/data-lending'} element={<DataPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
