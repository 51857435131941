import React, { FormEventHandler, useRef, useState } from 'react'
import './Lending.scss';

import AuthService from '../../services/AuthService';

import top from '../../img/land/top.jpg'
import pollution from '../../img/land/Picture1.png'
import Picture2 from '../../img/land/Picture2.png'
import Picture3 from '../../img/land/Picture3.png'
import logo from '../../img/land/logo.png'

import res from '../../img/land/t.png'
import res1 from '../../img/land/t1.png'
import res2 from '../../img/land/t2.png'
import res3 from '../../img/land/t3.png'
import res4 from '../../img/land/t4.png'
import res5 from '../../img/land/t5.png'
import res6 from '../../img/land/t6.png'
import res7 from '../../img/land/t7.png'
import res8 from '../../img/land/t8.png'
import res9 from '../../img/land/t9.png'
import res10 from '../../img/land/t10.png'
import res11 from '../../img/land/t11.png'


import before from '../../img/land/before.png'
import after from '../../img/land/after.png'

const Lending = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  let filedRef = useRef<HTMLDivElement>(null);
  let success = useRef<HTMLDivElement>(null);
  let hello = useRef<HTMLDivElement>(null);
  let form = useRef<HTMLFormElement>(null);

  const hendlerClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const filed = filedRef.current
    if (name && email && phone) {
      filed?.classList.remove('error__text')
      hello.current?.classList.add('hidden')
      form.current?.classList.add('hidden')
      success.current?.classList.remove('hidden')
      AuthService.emailCreate(email, name, phone).then((e) => {});
    } else {
      filed?.classList.add('error__text')
    }
  }

  return (
    <div className="container">
      <section className="section section__header">
        <div className="logo__container">
          <div className="logo__title">
            <img className="logo" src={logo} alt='' />
            <h1>NEO-Ecology</h1>
          </div>
          <div className="description">
            <div className="description__item"></div>
            <div className="description__item">
              <p className="item item-bolt">
                Water expertise and
                progressive aquaculture
              </p>
              <p className="item">
                Increase in margin income
                tо 50 000 USD per hectare
              </p>
            </div>
          </div>
        </div>
        <img className="section__image" src={top} alt='' />
      </section>
      <div className="stat">
        <div className="stat__item">
          <img className="img__warning" src={pollution} alt='' />
          <span>5 000 000 m3</span>
            Treated
            wastewater
        </div>
        <div className="stat__item">
          <img src={Picture2} alt='' />
          <span>50 000 $ / ha</span>
          Margin
          improvement
        </div>
        <div className="stat__item">
          <img src={Picture3} alt='' />
          <span>1 000 000 kg</span>
          Grown
          hydrobionts
        </div>
      </div>
      <div className="state">
        <h1>ALGORITHM</h1>
        <div className="state__item">
          <div className="title__wrapper">
            <span className="title">I STAGE</span>
            <span>1 month</span>
          </div>
          <span className="line"></span>
          <div className="wrapper_l">
            <div>
              <img src={res} alt="" />
              <img src={res1} alt="" />
              <img src={res2} alt="" />
              <img src={res3} alt="" />
            </div>
            <div className="desk">
              <ul>
                <li>Monitoring</li>
                <li>Modeling</li>
                <li>Risk assessment</li>
              </ul>
              <ul>
                <li>Inspection of reservoirs</li>
                <li>Capture of test objects</li>
                <li>Water sampling</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="state__item">
          <div className="title__wrapper">
            <span className="title">II STAGE</span>
            <span>3 months</span>
          </div>
          <span className="line"></span>
          <div className="wrapper_l">
            <div>
              <img src={res4} alt="" />
              <img src={res5} alt="" />
              <img src={res6} alt="" />
              <img src={res7} alt="" />
            </div>
            <div className="desk">
              <ul>
                <li>Fish farming</li>
                <li>Zooplankton feed</li>
                <li>Ecosystem model</li>
              </ul>
              <ul>
                <li>Fight against blooming</li>
                <li>Catching fish</li>
                <li>Cleaning</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="state__item">
          <div className="title__wrapper">
            <span className="title">III STAGE</span>
            <span>8 months</span>
          </div>
          <span className="line"></span>
          <div className="wrapper_l wrapper_l-first-child">
            <div> <img className="mb" src={res8} alt="" />
              <img className="mb" src={res9} alt="" />
              <img className="mb" src={res10} alt="" />
              <img className="mb" src={res11} alt="" />
            </div>
            <div className="desk">
              <ul>
                <li>Fish farming process</li>
                <li>Control catches</li>
                <li>Sanitary control</li>
              </ul>
              <ul>
                <li>Zooplankton growth</li>
                <li> Drying</li>
                <li>Water purification</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="before">
        <div className="dc">
          <h1>BEFORE</h1>
          <p>2017</p>
          <ul>
            <li>Discharge of untreated wastewater &gt; 10,000 m3/day</li>
            <li>Fish bother when Cyanobacteria bloom</li>
            <li>Bad smell for many kilometers</li>
          </ul>
        </div>
        <img src={before} alt="" srcSet="" />
      </div>
      <div className="before">
        <div className="dc">
          <h1>AFTER</h1>
          <p>2018 - 2021</p>
        </div>
        <img src={after} alt="" srcSet="" />
      </div>
      <div className="propas">
        <h1>PROPOSAL</h1>
        <div className="wrapper_l">
          <ol>
            <li>
              Construction of bio-ponds
              for wastewater treatment
              with organic concentration <br />
              <span>BOD &gt; 1000 mg/l</span>
            </li>
            <li>
              Rent of ownerless ponds
              for the cultivation of
              valuable hydrobionts on
              the square from <span>10 hectares</span>
            </li>
            <li>
              Improvement of reservoirs
              for guaranteed water supply
            </li>
          </ol>
        </div>
      </div>

      <div className="wrapper__form">
        <h2 ref={hello} className="form-text">Do you have questions? Leave your details and we will contact you!</h2>
        <h2 ref={success} className="form-textSs hidden">Thank you, we will contact you shortly.</h2>
        <form ref={form} className="form">
          <div className="form__body">
            <input className="name" value={name} onChange={e => setName(e.target.value)} placeholder="Name" type="text" />
            <input className="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" type="email" />
            <input className="phone" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone" type="text" />
            <p ref={filedRef} className="mess">Please fill in all fields</p>
          </div>
          <button onClick={(e) => hendlerClick(e)} className="btn">send</button>
        </form>
      </div>
      <div className="wrapper__contact">
          <h3>Neo-Ecology LLP</h3>
          <p>Tel: +7 (705) 852 37 16</p>
          <p>E-mail: neo.ecology.kz@gmail.com</p>
          <p>050060, Almaty, Bostandyksky district, Khodzhanova str., 2/2</p>
      </div>
    </div>
  )
}

export default Lending;