import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from '../interfaces/AuthResponse';

export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/login', {
      email,
      password,
    })
  }

  static async registration(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/registration', {
      email,
      password,
    })
  }

  static async logout(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post('/logout');
  }

  static async emailCreate(email: string, name: string, phone: string): Promise<AxiosResponse<any>> {
    return $api.post('/email/create', {
      email, name, phone
    });
  }

  static async getEmails(pass: string): Promise<AxiosResponse<any>> {
    return $api.post('/email', {
      pass: pass
    });
  }
}