import { AxiosResponse } from 'axios';
import React, { useState } from 'react'
import AuthService from '../../services/AuthService';

import './DataPage.scss'


interface Email {
  date_of_creation: string, 
  _id: string, 
  email: string, 
  phone: string, 
  name:string,
}
const DataPage: React.FC = () => {

  const [emails, setEmails] = useState<Email[]>();
  const [pass, setPass] = useState('');
  const [error, setError] = useState('');

  const get = async () => {
    const res = await AuthService.getEmails(pass);
    if (!res.data?.error) {
      setEmails(res.data)
      setError('');
    }
    else setError(res.data?.error);
  }

  const hendlerPass = (e: React.MouseEvent) => {
    e.preventDefault();
    if(pass.length > 5) get()
  }

  return (
    <div className='admin'>
      <div className='admin__wrapper'>
        <input value={pass} onChange={(e) => setPass(e.target.value)} type="text" />
        <button onClick={(e) =>hendlerPass(e)}>GET DATA</button>
        <div className='admin__data'>
          {error}
          {emails?.map((user) =><div className='admin__user'>
            <p>name: {user.email}</p>
            <p>email: {user.phone}</p>
            <p>phone: {user.name}</p>
            <p>date of creation: {user.date_of_creation}</p>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default DataPage